import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'

import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"


import { login, getMe, getEnums } from "@/api"

Vue.config.productionTip = false
Vue.use(Toast, {})

new Vue({
  router,
  i18n,
  // primaryColor: '#FFAE20',
  primaryColor: '#FFAE20',
  // image s3 url
  basePicUrl: 'https://candidates-images.s3.fr-par.scw.cloud',
  render: h => h(App),
  
  data() {
    const token = window.localStorage.getItem('token')
    const user = JSON.parse(window.localStorage.getItem('user'))

    return {
      token,
      user,
      saveAlertFilters: {},
      enums: {},
      enumLoaded: false,
    }
  },

  methods: {
    async login({email, password}) {
      const { token, user } = await login({ email, password })
      window.localStorage.setItem('token', token)
      window.localStorage.setItem('user', JSON.stringify(user))
      this.token = token
      this.user = user
    },

    async getMe() {
      try {
        const { user } = await getMe({token: this.token})
        window.localStorage.setItem('user', JSON.stringify(user))
        this.user = user
      } catch (err) {
        console.error(err)
        window.localStorage.removeItem('token')
        window.localStorage.removeItem('user')
      }
    },

    async loadEnums() {
      const enums = await getEnums()
      this.enums = enums
      this.enumLoaded = true
    },

    async logout() {
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('user')
      this.token = null
      this.user = null
    },
  },

  mounted() {
    this.loadEnums()

    if(this.token) {
      this.getMe()
    }
  }

}).$mount('#app')
