<template>
  <div class="widget widget_noo_advanced_search_widget">
    <h4 class="widget-title">Recherche</h4>
    <form class="widget-advanced-search" @submit.prevent="onSubmit" v-if="enumLoaded">
      <div class="form-group">
        <label class="h5 mt-0">Mots clés</label>
        <input
          type="text"
          class="form-control"
          id="search-keyword"
          name="search"
          placeholder="Nom, prénom, job, ..."
          value=""
        />
      </div>
      <div class="form-group">
        <label class="h5" for="search-category">Fonction</label>
        <div class="advance-search-form-control">
          <select
            name="jobTitle"
            class="form-control-chosen form-control"
            id="search-category"
          >
            <option v-for="jobTitle in jobTitles" :value="jobTitle.value" :key="jobTitle.value">
              {{ jobTitle.text }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group">
        <label class="h5">Secteur</label>
        <div class="advance-search-form-control">
          <select
            name="sector"
            class="form-control-chosen form-control"
            id="search-sector"
          >
            <option v-for="sector in sectors" :value="sector.value" :key="sector.value">
              {{ sector.text }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group">
        <label class="h5">Région</label>
        <div class="advance-search-form-control">
          <select name="location" class="form-control-chosen form-control" id="search-location">
            <option v-for="location in regions" :value="location.value" :key="location.value">
              {{ location.text }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group">
        <label class="h5">Expérience</label>
        <div class="advance-search-form-control">
          <select
            name="experience"
            class="form-control-chosen form-control"
            id="search-experience"
          >
            <option v-for="xp in experience" :value="xp.value" :key="xp.value">
              {{ xp.text }}
            </option>
          </select>
        </div>
      </div>
      <div class="fav-filters form-group">
        <label class="h5">Disponibilité</label>
        <div class="advance-search-form-control">
          <select name="available" class="form-control-chosen form-control" id="search-available">
            <option value="all">Tous les Profils</option>
            <option value="active">Disponibles</option>
          </select>
        </div>
      </div>
      <div class="fav-filters form-group">
        <label class="h5">Enregistrés / Favoris</label>
        <div class="advance-search-form-control">
          <select name="fav" class="form-control-chosen form-control" id="search-fav">
            <option value="all">Tous les Profils</option>
            <option value="viewed">Profils enregistrés</option>
            <option value="fav">Profils favoris</option>
          </select>
        </div>
      </div>
      <div class="submit-cnt">
        <button v-if="isLoggedIn" type="button" class="btn btn-primary btn-search-submit" @click="() => onSubmit()">
          Rechercher
        </button>
        <button v-else type="button" class="btn btn-primary btn-search-submit" data-rel="loginModal">
          Rechercher
        </button>
        <button v-if="!isHomepage" type="button" class="ml-2 btn btn-secondary" @click="onResetFilters">Effacer les filtres</button>
      </div>
    </form>
  </div>
</template>

<script>

export default {
  props: {
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {

    }
  },

  computed: {
    enums() {
      return this.$root.$data.enums
    },
    enumLoaded() {
      return this.$root.$data.enumLoaded
    },
    jobTitles() {
      if(!this.enums.JOB_TITLES) return []

      return [
        { value: '', text: 'Toutes les fonctions'},
        ...this.enums.JOB_TITLES.map(a => ({text: a, value: a}))
      ]
    },
    sectors() {
      if(!this.enums.SECTORS) return []

      return [
        { value: '', text: 'Tous les secteurs'},
        ...this.enums.SECTORS.map(a => ({text: a, value: a}))
      ]
    },
    regions() {
      if(!this.enums.REGIONS) return []

      return [
        { value: '', text: 'Toutes les régions'},
        ...this.enums.REGIONS.map(a => ({text: a, value: a}))
      ]
    },
    experience() {
      if(!this.enums.EXPERIENCE_TITLES) return []

      return [
        { value: '', text: 'Toutes les expériences'},
        ...this.enums.EXPERIENCE_TITLES.map(a => ({text: a, value: a}))
      ]
    },


    isLoggedIn() {
      return !!this.$root.$data.token;
    },
    isHomepage() {
      return this.$route.path === '/';
    },
  },

  methods: {
    onSubmit() {
      const form = document.querySelector('.widget-advanced-search')
      const formData = new FormData(form)
      const data = Object.fromEntries(formData)

      if(data.fav === 'viewed') {
        data.isViewed = true
      } else if(data.fav === 'fav') {
        data.isFav = true
      }
      delete data.fav

      if(data.available === 'active') {
        data.isAvailable = true
      }
      delete data.available
        

      this.$emit('submit', data)
    },
    onResetFilters() {
      this.$emit('submit', {})
    },
    initFields(filters) {
      if(filters.search) {
        document.querySelector('#search-keyword').value = filters.search
      } else {
        document.querySelector('#search-keyword').value = ''
      }

      if(filters.jobTitle) {
        document.querySelector('#search-category').value = filters.jobTitle
      } else {
        document.querySelector('#search-category').value = ''
      }

      if(filters.experience) {
        document.querySelector('#search-experience').value = filters.experience
      } else {
        document.querySelector('#search-experience').value = ''
      }

      if(filters.sector) {
        document.querySelector('#search-sector').value = filters.sector
      } else {
        document.querySelector('#search-sector').value = ''
      }

      if(filters.location) {
        document.querySelector('#search-location').value = filters.location
      } else {
        document.querySelector('#search-location').value = ''
      }

      if(filters.isFav) {
        document.querySelector('#search-fav').value = 'fav'
      } else if (filters.isViewed) {
        document.querySelector('#search-fav').value = 'viewed'
      } else {
        document.querySelector('#search-fav').value = 'all'
      }

      if(filters.isAvailable) {
        document.querySelector('#search-available').value = 'active'
      } else {
        document.querySelector('#search-available').value = 'all'
      }
    },
  },

  mounted() {
    this.initFields(this.filters)
  },
}
</script>