// api connector
import axios from 'axios'

const verbose = false

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 10000,
})

export const listCandidates = async ({skip = 0, take = 10, userId, ...filters} = {}) => {
  const { data } = await api.get(`/candidates?skip=${skip}&take=${take}${
    filters.search ? `&search=${filters.search}` : ''
  }${
    filters.jobTitle ? `&jobTitle=${filters.jobTitle}` : ''
  }${
    filters.location ? `&location=${filters.location}` : ''
  }${
    filters.sector ? `&sector=${filters.sector}` : ''
  }${
    filters.experience ? `&experience=${filters.experience}` : ''
  }${
    filters.isViewed ? `&isViewed=${filters.isViewed}` : ''
  }${
    filters.isFav ? `&isFav=${filters.isFav}` : ''
  }${
    filters.isAvailable ? `&isAvailable=${filters.isAvailable}` : ''
  }${
    filters.custom ? `&custom=${filters.custom}` : ''
  }${
    userId ? `&userId=${userId}` : ''
  }`)
  
  if(verbose) console.log('listCandidates', data)
  return data
}

export const getSecretCandidate = async ({id, secret}) => {
  const { data } = await api.get(`/candidates/secret/${id}`, {
    params: {
      secret,
    },
  })
  
  if(verbose) console.log('getCandidate', data)
  return data
}

export const getCandidate = async ({id, token}) => {
  const { data } = await api.get(`/candidates/${id}`, {
    headers: {
      token,
    }
  })
  
  if(verbose) console.log('getCandidate', data)
  return data
}

export const getEnums = async () => {
  const { data } = await api.get('/enums')
  
  if(verbose) console.log('getEnums', data)
  return data
}

export const login = async ({email, password}) => {
  const { data } = await api.post('/login', { email, password })

  if(verbose) console.log('login', data)
  return data
}

export const getMe = async ({token}) => {
  const { data } = await api.get('/me', {
    headers: {
      token,
    }
  })

  if(verbose) console.log('getMe', data)
  return data
}

export const setFav = async ({token, candidateId, isFav}) => {
  await api.post(`/candidates/${candidateId}/fav`, { isFav }, {
    headers: {
      token,
    }
  })
}

export const createAlert = async ({token, alert}) => {
  await api.post('/alerts', {alert}, {
    headers: {
      token,
    }
  })
}

export const deleteAlert = async ({token, id}) => {
  await api.delete(`/alerts/${id}`, {
    headers: {
      token,
    }
  })
}

export const updateCandidate = async ({id, secret, data}) => {
  const { data:resData } = await api.patch(`/candidates/${id}?secret=${secret}`, data)
  
  if(verbose) console.log('updateCandidate', data)
  return resData
}