<template>
  <div class="container-wrap">
    <div class="main-content container-fullwidth">
      <div class="row" v-if="lastCandidates">
        <div class="noo-main col-md-12">
          <div class="row">
            <div class="col-md-12">
              <div class="noo-job-search-wrapper">
                <div class="job-search-bg-image"></div>
                <div class="job-advanced-search container-boxed">
                  <div class="job-search-info">
                    <h2 class="search-top-title">
                      <span class="text-primary">L'annuaire</span> des commerciaux
                    </h2>
                    <p class="subtitle pl">
                      Bientôt 100 000 commerciaux référencés
                    </p>

                    <CandidateSearchForm @submit="onSearch" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row home-resumes">
            <div class="col-md-12">
              <div class="job-list container-boxed mt-2">
                <h2>Derniers profils ajoutés</h2>
                <div class="row resumes">
                  <div class="posts-loop">
                    <CandidateList :candidates="lastCandidates" />
                  </div>
                </div>
                <div class="row mt-0 mb-2">
                  <router-link v-if="isLoggedIn" to="/profiles">
                    <button type="button" class="btn btn-primary">
                      Voir les profils
                    </button>
                  </router-link>
                  <div v-else>
                    <button type="button" class="btn btn-primary" data-rel="loginModal">
                      Voir les profils
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CandidateSearchForm from "@/components/CandidateSearchForm";
import CandidateList from "@/components/CandidateList";
import { listCandidates } from "@/api";
export default {
  name: "HomeView",

  components: {
    CandidateSearchForm,
    CandidateList,
  },

  data() {
    return {
      lastCandidates: [],
    };
  },

  computed: {
    primaryColor() {
      return this.$root.$options.primaryColor;
    },
    isLoggedIn() {
      return !!this.$root.$data.token;
    }
  },

  methods: {
    onSearch(searchParams) {
      // filter out empty values
      searchParams = Object.keys(searchParams).reduce((acc, key) => {
        if (searchParams[key]) {
          acc[key] = searchParams[key];
        }
        return acc;
      }, {});

      this.$router.push({ path: "/profiles", query: searchParams });

      // save to local storage
      localStorage.setItem("candidateSearch", JSON.stringify(searchParams));
    },
    async getLastCandidates() {
      const { candidates } = await listCandidates({
        take: 5,
      });

      this.lastCandidates = candidates;
    },
  },

  created() {
    this.getLastCandidates();
  },
};
</script>

<style lang="scss">
.noo-job-search-wrapper .job-search-bg-image {
  height: 540px;
}

.job-advanced-search {
  max-width: 1200px;
  width: 80%;
  margin-top: 30px;

  .search-top-title {
    font-size: 46px;
    font-family: "Droid Serif", sans-serif;
    font-style: bolder;
    line-height: 1.3em;

    b {
      color: v-bind(primaryColor);
    }
  }

  .subtitle {
    font-size: 1.6em;
    margin-bottom: 40px;
  }

  .search-main-title {
    color: #fff;
    font-weight: 300;
  }

  .widget_noo_advanced_search_widget {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 5px;
    padding: 2px 5px 10px;

    .widget-title {
      display: none;
    }

    .widget-advanced-search {
      font-size: 0;

      & > * {
        font-size: 1rem;
      }
    }

    #search-keyword {
      min-width: auto;
    }

    .form-group {
      &.fav-filters {
        display: none;
      }
    }

    @media (min-width: 768px) {
      .form-group {
        display: inline-block;
        margin-bottom: 12px;
        width: 20%;
        overflow: hidden;

        input {
          max-width: 100%;
          min-width: none;
        }
      }
      .submit-cnt {
        width: 100%;
        text-align: right;

        .btn-search-submit {
          display: inline-block;
          width: 19%;
          margin-right: 0.5357142857142857em;
          background-color: v-bind(primaryColor);
          font-size: 1.2em;
          font-weight: 600;
          text-align: center;
          padding: 0;
          height: 40px;
        }
      }
    }
  }
}

.home-resumes {
  .job-list {
    max-width: 1200px;
    width: 80%;
  }

  .button {
    width: 100%;
  }

  .resumes {
    .resume-table {
      min-height: auto !important;
    }
  }
}

.onlysales-others {
  .noo-icon {
    color: v-bind(primaryColor);
    font-size: 5em;
  }
}
</style>